@import "../variable";
@import "../col";


.accountsec { height: 100vh; overflow: hidden;
  .container-fluid { height: 100%; }
  .row { height: 100%; }
  h3.logintitle { margin-bottom: 50px; font-family: $fonts-black; font-size: 32px; }

  .form-group { width: 100%; margin: 0 0 15px; font-family: $fonts-sf; font-weight: 500; letter-spacing: .5px;
    &:last-child { margin: 0; }
    input { width: 100%; background: #E8E6E6; height: 50px; margin-bottom: 15px; padding: 0 15px; border-radius: 5px; color: $black; margin: 0 0 5px; font-family: $fonts-sf; font-weight: 500;
      &::-webkit-input-placeholder { color: $black; font-size: 15px; opacity: 1; font-weight: 400; }
      &::-moz-placeholder { color: $black; font-size: 15px; opacity: 1; font-weight: 400; }
      &:-ms-input-placeholder { color: $black; font-size: 15px; opacity: 1; font-weight: 400; }
      &:-moz-placeholder { color: $black; font-size: 15px; opacity: 1; font-weight: 400; }
    }
    select { width: 100%; background-color: #E8E6E6; height: 50px; margin-bottom: 0; padding: 0 15px; border-radius: 5px; color: $black; font-family: $fonts-sf; font-weight: 300;
      option { background: #E8E6E6; color: #000; }
    }
    &.align-right { text-align: right; }
    p { margin-bottom: 15px; font-size: 12px; }
    .text-error { font-family: $fonts-sf; font-weight: 300; }
  }
}
.login-box { background: #ffffff; height: 100%; color: $black; padding: 0 100px; }
.create-account { flex: 0 0 60%; max-width: 60%; }
.create-account-img { flex: 0 0 40%; max-width: 40%; }
.account-signin { flex: 0 0 60%; max-width: 60%; }
.account-signin-img { flex: 0 0 40%; max-width: 40%; }
.login-box-inner { width: 100%; padding: 0; max-width: 360px;
  &.d-flex { max-width: unset;
    ul { width: 100%; }
  }
}
.forgot-password {
	flex: 0 0 60%;
	max-width: 60%;
}
.login-box-content {
  flex: 0 0 40%;
	max-width: 40%;
}
.login-content-inner { max-width: 400px; width: 100%; }
p.form-bottom-line { padding: 70px 0 0 0; text-align: center; font-size: 12px; font-size: 16px; font-family: $fonts-sf; font-weight: 300; letter-spacing: .5px; font-weight: 500;
  span { font-weight: 700; }
}
.uploadimgi-inner { padding: 0 30px 0 0; }
.uploadimg-box { background: rgba($color: $black, $alpha: .25); width: 150px; height: 150px; border-radius: 100px; background-repeat: no-repeat; background-position: center center; cursor: pointer; }
.acc-header { width: 100%; max-width: 516px; font-family: $fonts-sf; font-weight: 500; }
.acc-footer { width: 100%; padding: 70px 0 0 0; text-align: center; font-family: $fonts-sf; font-weight: 500; letter-spacing: .5px;
  .btn { max-width: 306px; width: 100%; background: $theme-color; color: $white; border-radius: 25px; height: 50px; font-family: $fonts-black; -webkit-box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.25); -moz-box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.25); box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.25);
    &:hover { background: $black; color: $white; }
  }
}
.acc-header-top { max-width: 516px; font-family: $fonts-sf; font-weight: 300;
  @extend .d-flex; margin: 0 0 50px; width: 100%;
  a { color: $white; font-size: 13px; }
  img { margin: -5px 10px 0 0; max-width: 16px; }
}
.login-content { margin: 0 auto; height: 100%; }
.login-content-img { padding: 0; height: 100vh;
  img { height: 100%; width: 100%; overflow: hidden; }
}
.login-content-small {
  .acc-header-top { max-width: 360px; }
  .acc-header { max-width: 360px; }
}
.acc-img-upload {
  &.login-box-inner { max-width: 535px; }
  .login-box-inner { padding: 0 30px 0 20px; -ms-flex: 0 0 150px; -webkit-box-flex: 0 0 150px; flex: 0 0 150px;
  }
}

.uploadimg-box { overflow: hidden; position: relative;
  figure { width: 100%; height: 100%;
    img { width: 100%; height: 100%; object-fit: cover; }
  }
  input { position: absolute; left: 0; top: 0; width: 100%; height: 100%; opacity: 0; }
}

.login-content-inner { max-width: 400px; width: 100%; }
.login-box-content { padding: 0 60px 94px; flex-direction: column; background-repeat: no-repeat; background-position: center center; background-size: cover;
  h3 { display: inline-block; color: $white; font-family: $fonts-black; background: $theme-color; padding: 0 30px 0 15px; margin-bottom: 24px; font-size: 43px; line-height: 56px; text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.38);
    &:last-child {margin-bottom: 0;}
  }
}
.forgot-header { max-width: 360px; }

@include responsive(xl) {
  .acc-header-top { margin: 0 0 25px; }
}

@include responsive(md) {
  .login-box { padding: 50px; }
  .accsecnext {
    .login-box { padding: 50px;
    }
    .acc-img-upload {
      .login-box-inner { padding: 0 0 30px;
        .uploadimg-box { margin: 0 auto; }
      }
    }
    .login-box-inner { -ms-flex-direction:column;-webkit-box-orient:vertical;flex-direction:column; }
  }
}

@include responsive(lg) {
  // .account-signin { flex: 0 0 calc(100% - 600px); max-width: calc(100% - 600px); }
  // .account-signin-img { flex: 0 0 600px; max-width: 600px; }
  .login-box { padding: 50px; }
  .login-box-content {
    padding: 0 30px 30px;
  }
}

@include responsive(md) {
  .account-signin { flex: 0 0 100%; max-width: 100%;
    .login-content { min-height: auto; }
  }
  .create-account { flex: 0 0 100%; max-width: 100%;
    .login-content { min-height: 50vh; }
  }
  .accountsec { height: auto; }
  .account-signin-img { flex: 0 0 100%; max-width: 100%; }
  .create-account { flex: 0 0 100%; max-width: 100%; }
  .create-account-img { flex: 0 0 100%; max-width: 100%; }
  .forgot-password { min-height: 100vh; }
  .login-box {padding: 30px 15px;min-height: 50vh;}
  .acc-footer {padding: 20px 0 0 0;}
  p.form-bottom-line {padding: 20px 0 0 0;}
  .login-box-content { flex: 0 0 100%; max-width: 100%; padding: 30px; min-height: 50vh; }
  .forgot-password { flex: 0 0 100%; max-width: 100%; min-height: 50vh; }
}

@include responsive(sm) {
  .login-content-img { height: auto;
    img { height: auto; }
  }
  // .login-box-content { padding: 50px; }
}

@include responsive(xs) {
  // .login-box { padding: 50px; }
  .login-box-inner { max-width: 100%; }
  p.form-bottom-line { padding: 20px 0 0 0; }
  .login-content { min-height: calc(100vh - 100px); }
  .login-box-content {
    padding: 30px 15px;
    h3 { font-size: 24px; }
  }
  .login-content-small {
    .acc-header-top { max-width: 100%; margin: 0 0 20px; }
    .acc-header { max-width: 100%; }
  } 
  .accountsec h3.logintitle { margin-bottom: 15px; }
  .forgot-header { max-width: 100%; margin: 0 0 20px; }
  .acc-header.forgot-header { margin-bottom: 0; }
  .login-box-content {
    h3 { margin-bottom: 15px; line-height: 46px; }
  }
}

@include responsive(mbv) {
  .accsecnext .login-box { padding: 50px 20px; }
}